import { Grid, Typography, Box } from "@mui/material";
import ContentContainer from "../../app/common/container/ContentContainer";
import RoomOutlinedIcon from "@mui/icons-material/RoomOutlined";
import ScheduleOutlinedIcon from "@mui/icons-material/ScheduleOutlined";
import PhoneOutlinedIcon from "@mui/icons-material/PhoneOutlined";
import { TimerOutlined } from "@mui/icons-material";

const LocationInfo = () => {
  return (
    <ContentContainer>
      <Grid container spacing={2} alignItems='center'>
        <Grid item xs={12} sx={{ margin: "2rem 0" }}>
          <Typography
            variant='h4'
            align='center'
            gutterBottom
            fontWeight='bold'
          >
            Location
          </Typography>
        </Grid>
        <Grid item xs={12} md={6}>
          <Box
            sx={{
              width: "100%",
              height: 300,
              overflow: "hidden",
            }}
          >
            <iframe
              title='Google Map'
              src='https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2607.948483064531!2d-123.13675642351699!3d49.18256417137793!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x54867502bb109b0b%3A0x931e8fd2082e712e!2sTwo%20Horns%20Korean%20BBQ%20House!5e0!3m2!1sen!2sca!4v1716954350028!5m2!1sen!2sca'
              width='100%'
              height={300}
              frameBorder='0'
              style={{ border: 0 }}
              allowFullScreen
            ></iframe>
          </Box>
        </Grid>
        <Grid item xs={12} md={6}>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              p: 1,
              marginBottom: 2,
            }}
          >
            <RoomOutlinedIcon
              sx={{
                fontSize: { xs: 20, sm: 40 },
                marginInline: {
                  xs: 1,
                  sm: 5,
                },
              }}
            />
            <Typography
              variant='subtitle1'
              sx={{ fontSize: { xs: 10, sm: 18 } }}
            >
              4231 Hazelbridge Way, Richmond, BC V6X 3L7
            </Typography>
          </Box>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              p: 1,
              marginBottom: 2,
            }}
          >
            <TimerOutlined
              sx={{
                fontSize: { xs: 20, sm: 40 },
                marginInline: {
                  xs: 1,
                  sm: 5,
                },
              }}
            />
            <Box>
              <Typography
                variant='subtitle1'
                sx={{ fontSize: { xs: 10, sm: 18 }, marginBottom: 1 }}
              >
                Sun- Thu (Tue opens from 5 pm)
              </Typography>
              <Typography
                sx={{ fontSize: { xs: 10, sm: 18 }, marginBottom: 2 }}
              >
                11:30am - 3:00pm, 5:00pm - 9:30pm
              </Typography>
              <Typography
                variant='subtitle1'
                sx={{ fontSize: { xs: 10, sm: 18 }, marginBottom: 1 }}
              >
                Fri - Sat :
              </Typography>
              <Typography
                sx={{ fontSize: { xs: 10, sm: 18 }, marginBottom: 2 }}
              >
                11:30pm - 3:00pm, 5:00pm - 10:30pm
              </Typography>
            </Box>
          </Box>

          <Box sx={{ display: "flex", alignItems: "center", p: 1 }}>
            <PhoneOutlinedIcon
              sx={{
                fontSize: { xs: 20, sm: 40 },
                marginInline: {
                  xs: 1,
                  sm: 5,
                },
              }}
            />
            <Typography
              variant='subtitle1'
              sx={{ fontSize: { xs: 10, sm: 18 } }}
            >
              (604) 214 1488
              <br />
              info@agorafield.com
            </Typography>
          </Box>
        </Grid>
      </Grid>
    </ContentContainer>
  );
};

export default LocationInfo;
