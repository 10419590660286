import React from "react";
import { Box, Typography, useMediaQuery, useTheme } from "@mui/material";

const RestaurantHeader = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  return (
    <Box
      sx={{
        // border: "1px solid #ccc",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        height: "30rem",
        mt: 2,
        px: { xs: 1, sm: 2, md: 3 },
      }}
    >
      {!isMobile && (
        <>
          <Box
            component='img'
            src={`${process.env.PUBLIC_URL}/assets/checkout/result/main-1.png`}
            sx={{
              position: "absolute",
              top: 0,
              left: 0,
              width: { xs: "50%", sm: "20%" },
              height: "auto",
              zIndex: -1,
            }}
          />
          <Box
            component='img'
            src={`${process.env.PUBLIC_URL}/assets/common/Ellipse 17.png`}
            sx={{
              position: "absolute",
              top: 50,
              left: -30,
              width: { xs: "30%", sm: "30%" },
              height: "auto",
              zIndex: -2,
            }}
          />
          <Box
            component='img'
            src={`${process.env.PUBLIC_URL}/assets/common/Ellipse 17.png`}
            sx={{
              position: "absolute",
              top: 300,
              right: 0,
              width: { xs: "30%", sm: "30%" },
              height: "auto",
              zIndex: 1,
            }}
          />
        </>
      )}
      <Box
        component='img'
        sx={{
          height: "auto",
          width: {
            xs: `calc(3.75rem * 2.72)`,
            sm: `calc(9.5rem * 2.72)`,
          },
          marginBottom: "2rem",
        }}
        alt='Logo'
        src={`${process.env.PUBLIC_URL}/assets/logo-title.png`}
      />
      <Typography
        variant='subtitle1'
        align='center'
        sx={{
          fontFamily: "Noto Sans",
          fontWeight: "semi-bold",
          fontSize: { xs: "0.9rem", sm: "1.5rem" },
          maxWidth: "50rem",
        }}
      >
        Indulge in the exquisite flavors of premium Korean and Japanese BBQ at
        our upscale steakhouse. Experience the art of grilling with the finest
        cuts, meticulously crafted to elevate your dining journey.
      </Typography>
    </Box>
  );
};

export default RestaurantHeader;
