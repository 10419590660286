import {
  ShoppingCartOutlined,
  AccountCircle,
  ArrowBackIos,
  AccountCircleOutlined,
  AccountCircleRounded,
  AccountCircleSharp,
} from "@mui/icons-material";
import { Box } from "@mui/system";
import { Link, NavLink, useLocation } from "react-router-dom";
import { useStore } from "../stores/store";
import { observer } from "mobx-react-lite";
import {
  AppBar,
  Badge,
  Button,
  colors,
  Grid,
  IconButton,
  List,
  ListItem,
  Toolbar,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import AccountPage from "../../features/account/AccountPage";
import { useTheme as useCustomTheme } from "../contexts/ThemeContext";
import { router } from "../router/Routes";

const midLinks: any[] = [
  // { title: 'Order', path: '/orders' }
];

const rightLinks = [
  { title: "Log In", path: "/login" },
  { title: "Sign Up", path: "/register" },
];

const navStyles = {
  color: "inherit",
  backgroundColor: "#fff",
  textDecoration: "none",
  typography: "h6",
  "&:hover": {
    color: "grey.500",
  },
  "&.active": {
    color: "text.secondary",
  },
  zIndex: 1,
};

export default observer(function NavBar() {
  const { basketStore, userStore, modalStore } = useStore();
  const { user } = userStore;
  const location = useLocation();
  const isDefaultUrl = location.pathname !== "/login";
  const theme = useTheme();
  const { theme: customTheme } = useCustomTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const handleIconClick = () => {
    if (user?.roles?.includes("Admin")) {
      router.navigate(`${process.env.PUBLIC_URL}/admin`);
    } else {
      handleBasketClick();
    }
  };

  const handleAccountClick = () => {
    modalStore.openModal(<AccountPage />);
  };

  const handleBasketClick = () => {
    if (basketStore.ItemCountTotal === 0) {
      modalStore.openModal(
        <Grid
          container
          spacing={2}
          justifyContent='center'
          textAlign={"center"}
        >
          <Grid item xs={12}>
            <Typography
              variant='h6'
              sx={{ fontSize: { xs: "1.5rem", sm: "2rem" } }}
            >
              Cart Empty
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography
              variant='body1'
              sx={{ fontSize: { xs: "1rem", sm: "1.2rem" } }}
            >
              Your cart is empty.
            </Typography>
          </Grid>
          <Grid item xs={12} sx={{ mt: 2 }}>
            <Button
              variant='outlined'
              onClick={() => modalStore.closeModal()}
              sx={{
                borderRadius: "20px",
                textTransform: "none",
                color: "white",
                backgroundColor: customTheme.primaryColor,
                borderColor: customTheme.primaryColor,
                "&:hover": {
                  backgroundColor: customTheme.primaryHoverColor,
                  borderColor: customTheme.primaryHoverColor,
                },
                p: "5px 70px",
                fontSize: { xs: "0.8rem", sm: "1rem" },
              }}
            >
              OK
            </Button>
          </Grid>
        </Grid>
      );
    } else {
      window.location.href = `${process.env.PUBLIC_URL}/basket`;
    }
  };

  return (
    <AppBar
      position='fixed'
      sx={{
        zIndex: 10,
        bgcolor: "#fff",
        boxShadow: "0px 4px 5px rgba(0, 0, 0, 0.1)",
      }}
    >
      <Toolbar
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          width: "100%",
          color: "black", // Set text color to black
        }}
      >
        <Box display='flex' alignItems='center'>
          <IconButton
            component={Link}
            to='/'
            edge='start'
            sx={{
              borderRadius: "100%",
              textTransform: "none",
              color: "#000",
              backgroundColor: "#fff", // Set background color to customTheme.primaryColor (orange)
              "&:hover": {
                backgroundColor: "#fff", // Set background color to customTheme.primaryColor (orange)
                color: customTheme.primaryHoverColor, // Change hover background color to customTheme.primaryColor (orange)
              },
            }}
          >
            {isDefaultUrl ? (
              <Box
                component='img'
                src={`${process.env.PUBLIC_URL}/assets/logo.png`}
                alt='Logo'
                sx={{
                  width: isMobile ? "87px" : "204px",
                  height: "auto",
                  marginLeft: isMobile ? 0 : "2rem",
                }}
              />
            ) : (
              <>
                <ArrowBackIos
                  sx={{
                    fontSize: isMobile ? "1rem" : "2rem",
                    color: customTheme.primaryColor,
                  }}
                />
                <Typography
                  variant={isMobile ? "body2" : "h5"}
                  sx={{ color: customTheme.primaryColor }}
                >
                  Back To Main
                </Typography>
              </>
            )}
          </IconButton>
        </Box>

        {/* <List sx={{ display: "flex" }}>
          {midLinks.map(({ title, path }) => (
            <ListItem component={NavLink} to={path} key={path} sx={navStyles}>
              {title.toUpperCase()}
            </ListItem>
          ))}
        </List> */}

        <Box display='flex' alignItems='center'>
          {isMobile ? (
            <IconButton
              onClick={handleIconClick}
              size='large'
              edge='start'
              sx={{
                color: customTheme.primaryColor,
              }}
            >
              {user?.roles?.includes("Admin") ? (
                <Button
                  variant='outlined'
                  sx={{
                    color: customTheme.primaryColor,
                    borderColor: customTheme.primaryColor,
                    borderRadius: "20px",
                    textTransform: "none",
                    ml: { xs: 0, sm: -1.5 }, // Adjust left margin to make buttons closer
                    p: { xs: { sm: "0px 0px", md: "5px 15px" } }, // Adjust padding to make buttons longer
                    fontSize: { sm: "0.8rem", md: "1rem" },
                    "&:hover": {
                      borderColor: customTheme.primaryHoverColor,
                    },
                  }}
                >
                  Admin Page
                </Button>
              ) : (
                <Badge badgeContent={basketStore.ItemCountTotal} color='error'>
                  <ShoppingCartOutlined sx={{ color: "#000" }} />
                </Badge>
              )}
            </IconButton>
          ) : (
            <ListItem onClick={handleIconClick}>
              <Button
                variant='outlined'
                startIcon={
                  user?.roles?.includes("Admin") ? null : (
                    <ShoppingCartOutlined />
                  )
                }
                sx={{
                  borderRadius: "20px",
                  textTransform: "none",
                  backgroundColor: "white",
                  color: "black",
                  boxShadow: "none",
                  borderColor: "lightgray",
                  ml: { xs: 0, sm: -1.5 },
                  p: { xs: { sm: "0px 0px", md: "5px 15px" } },
                  fontSize: { sm: "0.8rem", md: "1rem" },
                  "&:hover": {
                    borderColor: "gray",
                  },
                }}
              >
                {user?.roles?.includes("Admin")
                  ? "Admin Page"
                  : `Cart (${basketStore.ItemCountTotal})`}
              </Button>
            </ListItem>
          )}
          {user ? (
            <IconButton
              edge='start'
              sx={{
                borderRadius: "100%",
                textTransform: "none",
                color: customTheme.primaryColor,
                backgroundColor: "#fff",
                "&:hover": {
                  backgroundColor: "#fff",
                  color: customTheme.primaryHoverColor,
                },
              }}
              onClick={handleAccountClick}
            >
              <AccountCircle sx={{ width: 30, height: 30 }} />
            </IconButton>
          ) : (
            <List sx={{ display: "flex" }}>
              <ListItem
                component={NavLink}
                to={"/register"}
                key={"/register"}
                sx={{ paddingInline: { xs: 0 } }}
              >
                <Button
                  variant='outlined' // Use outlined variant for outline style
                  sx={{
                    borderRadius: "20px",
                    textTransform: "none",
                    color: customTheme.primaryColor,
                    backgroundColor: "#fff", // Set background color to customTheme.primaryColor (orange)
                    borderColor: customTheme.primaryColor, // Set border color to customTheme.primaryColor (orange)
                    "&:hover": {
                      backgroundColor: "#fffbf7", // Change hover background color to customTheme.primaryColor (orange)
                      borderColor: customTheme.primaryHoverColor, // Change hover border color to customTheme.primaryColor (orange)
                    },
                    mr: { sx: -1 }, // Adjust right margin to make buttons closer
                    p: { xs: "5px 0px", sm: "5px 5px", md: "5px 15px" }, // Adjust padding to make buttons longer
                    fontSize: { xs: "0.8rem", sm: "0.8rem", md: "1rem" }, // Adjust font size to make buttons smaller
                    width: { sm: "90px", md: "90px" },
                  }}
                >
                  {"Sign Up"}
                </Button>
              </ListItem>

              <ListItem component={NavLink} to={"/login"} key={"/login"}>
                <Button
                  variant='outlined' // Use outlined variant for outline style
                  sx={{
                    borderRadius: "20px",
                    textTransform: "none",
                    color: "white",
                    backgroundColor: customTheme.primaryColor, // Set background color to customTheme.primaryColor (orange)
                    borderColor: customTheme.primaryColor, // Set border color to customTheme.primaryColor (orange)
                    "&:hover": {
                      backgroundColor: customTheme.primaryHoverColor, // Change hover background color to customTheme.primaryColor (orange)
                      borderColor: customTheme.primaryHoverColor, // Change hover border color to customTheme.primaryColor (orange)
                    },
                    ml: -1.5, // Adjust left margin to make buttons closer
                    mr: { xs: -1 }, // Adjust right margin to make buttons closer
                    p: { sm: "5px 10px", md: "5px 15px" }, // Adjust padding to make buttons longer
                    fontSize: { sm: "0.8rem", md: "1rem" }, // Adjust font size to make buttons smaller
                    width: { sm: "90px", md: "90px" },
                  }}
                >
                  {"Log In"}
                </Button>
              </ListItem>
            </List>
          )}
        </Box>
      </Toolbar>
    </AppBar>
  );
});
