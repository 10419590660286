import {
  Grid,
  Typography,
  Button,
  Card,
  CardMedia,
  CardContent,
  Box,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { MenuItem } from "../../../app/models/menuItem";
import { observer } from "mobx-react-lite";
import ContentContainer from "../../../app/common/container/ContentContainer";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import MenuItemDetails from "./MenuItemDetails";
import { useStore } from "../../../app/stores/store";
import { useTheme as useCustomTheme } from "../../../app/contexts/ThemeContext";

interface MostLovedMenuProps {
  mostLovedMenuItems: MenuItem[];
  handleAddToCart: (menuItem: MenuItem) => void;
}

export default observer(function Catalog({
  mostLovedMenuItems,
  handleAddToCart,
}: MostLovedMenuProps) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const { catalogStore, modalStore } = useStore();
  const { setMenuItem } = catalogStore;
  const { theme: customTheme } = useCustomTheme();

  const maxDescriptionLength = 60;

  return (
    <>
      <Grid container sx={{ my: 1, position: "relative" }}>
        {!isMobile && (
          <>
            {" "}
            <Box
              sx={{
                position: "absolute",
                top: "35%",
                width: "100%",
                height: "40%",
                backgroundColor: customTheme.primaryColor,
                zIndex: 2,
                visibility: isMobile ? "hidden" : "visible",
              }}
            />
            <Box
              component='img'
              src={`${process.env.PUBLIC_URL}/assets/common/Ellipse 17.png`}
              sx={{
                position: "absolute",
                top: 100,
                left: -50,
                width: { xs: "30%", sm: "30%" },
                height: "auto",
                zIndex: 1,
              }}
            />
            <Box
              component='img'
              src={`${process.env.PUBLIC_URL}/assets/common/Ellipse 17.png`}
              sx={{
                position: "absolute",
                top: -10,
                left: -60,
                width: { xs: "30%", sm: "30%" },
                height: "auto",
                zIndex: 1,
              }}
            />
            <Box
              component='img'
              src={`${process.env.PUBLIC_URL}/assets/common/Ellipse 17.png`}
              sx={{
                position: "absolute",
                top: -210,
                right: -110,
                width: { xs: "30%", sm: "30%" },
                height: "auto",
                zIndex: 1,
              }}
            />
            <Box
              component='img'
              src={`${process.env.PUBLIC_URL}/assets/common/main-2.png`}
              sx={{
                position: "absolute",
                top: -200,
                right: 0,
                width: { xs: "20%", sm: "20%" },
                height: "auto",
                zIndex: 0,
              }}
            />
            <Box
              component='img'
              src={`${process.env.PUBLIC_URL}/assets/common/main-3.png`}
              sx={{
                position: "absolute",
                top: -150,
                left: 0,
                width: { xs: "20%", sm: "20%" },
                height: "auto",
                zIndex: 1,
              }}
            />
          </>
        )}
        <ContentContainer>
          <Grid item xs={12}>
            <Typography
              variant='h4'
              align='center'
              gutterBottom
              fontWeight='bold'
              sx={{ my: 5 }}
            >
              Most Loved Menu
            </Typography>
            {isMobile ? (
              <Carousel
                showArrows={false}
                showStatus={false}
                showThumbs={false}
                showIndicators={false}
                infiniteLoop
                autoPlay
                interval={5000}
                stopOnHover={false}
                swipeable
                emulateTouch
              >
                {mostLovedMenuItems.map((menuItem) => (
                  <Grid
                    item
                    key={menuItem.id}
                    xs={12}
                    sm={6}
                    md={3}
                    marginBottom={1}
                  >
                    <Card
                      sx={{
                        border: "1px solid lightgray",
                        borderRadius: "10px",
                        boxShadow: "0px 2px 5px rgba(0, 0, 0, 0.3)",
                        position: "relative",
                        zIndex: 2,
                        marginInline: "0.2rem",
                        width: { xs: "98%", sm: "100%", md: "100%" },
                      }}
                    >
                      <CardMedia
                        component='img'
                        src={
                          menuItem.pictureUrl ||
                          `${process.env.PUBLIC_URL}/assets/placeholder.png`
                        }
                        alt={menuItem.name}
                        sx={{ height: 150, objectFit: "cover" }}
                      />
                      <CardContent sx={{ textAlign: "center" }}>
                        <Typography
                          variant='h6'
                          align='center'
                          sx={{ marginBottom: "0.5rem" }}
                        >
                          {menuItem.name}
                        </Typography>
                        <Typography variant='body2' align='center'>
                          {menuItem.description}
                        </Typography>
                        <Button
                          variant='contained'
                          color='primary'
                          size='small'
                          sx={{
                            padding: "5px 20px",
                            marginTop: 2,
                            textTransform: "capitalize",
                            backgroundColor: customTheme.primaryColor,
                            borderRadius: "20px",
                            "&:hover": {
                              backgroundColor: customTheme.primaryHoverColor,
                            },
                          }}
                          onClick={() => handleAddToCart(menuItem)}
                        >
                          Add To Cart
                        </Button>
                      </CardContent>
                    </Card>
                  </Grid>
                ))}
              </Carousel>
            ) : (
              <Grid container spacing={2}>
                {mostLovedMenuItems.map((menuItem) => (
                  <Grid
                    item
                    key={menuItem.id}
                    xs={12}
                    sm={3}
                    md={3}
                    marginBottom={1}
                  >
                    <Card
                      sx={{
                        border: "1px solid lightgray",
                        borderRadius: "10px",
                        boxShadow: "0px 2px 5px rgba(0, 0, 0, 0.3)",
                        position: "relative",
                        zIndex: 2,
                        cursor: "pointer",
                      }}
                    >
                      <CardMedia
                        component='img'
                        src={
                          menuItem.pictureUrl ||
                          `${process.env.PUBLIC_URL}/assets/placeholder.png`
                        }
                        alt={menuItem.name}
                        sx={{ height: 150, objectFit: "cover" }}
                        onClick={() => {
                          setMenuItem(menuItem);
                          modalStore.openModal(<MenuItemDetails />);
                        }}
                      />
                      <CardContent
                        sx={{
                          textAlign: "center",
                          minHeight: "220px",
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "space-between",
                        }}
                      >
                        <Typography
                          variant='h6'
                          align='center'
                          sx={{
                            marginBottom: "0.5rem",
                            fontSize: {
                              xs: "1rem",
                              sm: "1rem",
                              md: "1.25rem",
                            },
                          }}
                          onClick={() => {
                            setMenuItem(menuItem);
                            modalStore.openModal(<MenuItemDetails />);
                          }}
                        >
                          {menuItem.name}
                        </Typography>
                        <Typography
                          variant='body2'
                          align='center'
                          sx={{
                            marginBottom: "0.5rem",
                            fontSize: {
                              xs: "1rem",
                              sm: "0.75rem",
                              md: "1rem",
                            },
                          }}
                          onClick={() => {
                            setMenuItem(menuItem);
                            modalStore.openModal(<MenuItemDetails />);
                          }}
                        >
                          {menuItem.description.length > maxDescriptionLength
                            ? menuItem.description.substring(
                                0,
                                maxDescriptionLength
                              ) + "..."
                            : menuItem.description}
                        </Typography>
                        <Button
                          variant='contained'
                          color='primary'
                          size='small'
                          sx={{
                            padding: "5px 20px",
                            marginTop: 2,
                            textTransform: "capitalize",
                            backgroundColor: customTheme.primaryColor,
                            borderRadius: "20px",
                            fontSize: {
                              xs: "1rem",
                              sm: "0.75rem",
                              md: "1rem",
                            },
                            "&:hover": {
                              backgroundColor: customTheme.primaryHoverColor,
                            },
                          }}
                          onClick={() => handleAddToCart(menuItem)}
                        >
                          Add To Cart
                        </Button>
                      </CardContent>
                    </Card>
                  </Grid>
                ))}
              </Grid>
            )}
          </Grid>
        </ContentContainer>
      </Grid>
    </>
  );
});
